/* --
    Typography
-- */


// -- Font Family Imports

@font-face {
    font-family: 'MuktaExtraLight';
    src: url( '../../fonts/Mukta/Mukta-ExtraLight.ttf' ) format( 'truetype' );
}


@font-face {
    font-family: 'MuktaRegular';
    src: url( '../../fonts/Mukta/Mukta-Regular.ttf' ) format( 'truetype' );
}


.font {
    &-heading { 
        font-family: 'MuktaRegular', sans-serif !important; 
        font-weight: normal !important;
    } 

    &-body { 
        font-family: 'MuktaExtraLight', sans-serif !important; 
        font-weight: normal !important;

        &-bold { 
            font-family: 'MuktaRegular', sans-serif !important; 
            font-weight: normal !important;
        } 
    }
}

a { text-decoration: none; }


// Text Styles
.text {
    &-uppercase { text-transform: uppercase; }
    &-capitalize { text-transform: capitalize; }

    &-align {
        &-left { text-align: left; }
        &-center { text-align: center; }
        &-right { text-align: right; }
    }
}